:root {    
    --blue-color: #2B77FF;
    --blue-light-color: #11B1F0;
    --blue-used-color: #006CBE;
    --navy-color: #2D4CC9;
    --blue-input: #ECFFFF;
    --blue-offline: #4457E3;
    --blue-button-gradient: #1B74C1;
    --blue-button-gradient2: #0A9DD7;
    --blue-bg-top: #25dcdc;
    --blue-bg-bottom: #85F5F5;
    --blue-bg-box: #EDF5FF;
    --blue-blue-badge-left: #1E8EEF;
    --blue-blue-badge-right: #1A7ED6;
    --blue-circle: #CCDFFF;

    --black: #000000;
    --dark-black-color: #212121;
    
    --dark-gray: #565656;
    --gray-color: #838383;
    --gray-disabled: #CACACA;
    --gray-light-disabled: #F2F2F2;
    --gray-btn-disabled: #E0E0E0;
    
    --green-color: #00C1A5;
    --light-green-color:  #62A507;
    --dark-green-color:  #7EBB2A;  
    --green-light: #70B412;
    --green-light2: #EEF6DB;
    
    
    --white-color: #ffffff;
    --white-gray-color: #F8F8F8;
    
    --yellow-color: #E9B500;
    --yellow-mustard-color: #FFE071;
    --yellow-dark-color: #FFCE4F;
    
    --orange-color: #F08700;
    --orange-link: #E17F00;
    --orange-btn: #F49B00;
    --orange-light: #FEEEE3;
    
    --red-btn: #E84B4B;
    --red-drak-btn: #DB0000;

    --test: #DB0000;
}
