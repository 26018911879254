i {
    &[class*="_icon-"] {
        // insteaad of using the font-based icons
        mask-size: contain;
        mask-position: 50% 50%;
        mask-repeat: no-repeat;
        background: currentColor;
        width: 1.5rem;
        height: 1.5rem;
    }

    &[class*="_icon-coupon"] {
        mask-image: url("/assets/icon/coupon-icon.png");
    }
}