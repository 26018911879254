/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bulma/sass/utilities/initial-variables.sass";
@import "../node_modules/bulma/bulma.sass";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "assets/css/master-color.scss";
@import "assets/css/font.scss";
@import "assets/css/icon.scss";
@import "assets/css/button.scss";
@import "~@angular/cdk/overlay-prebuilt.css";
@import 'swiper/swiper-bundle.min.css';



// ------- MASTER ---------
html {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  touch-action: manipulation;
}

body {
  // background: linear-gradient(299.15deg, var(--blue-color) 8.77%, var(--blue-light-color) 100%);
  // color: var(--dark-black-color);
  // font-family: "Kanit";
  // font-size: 16px;
  // height: 100%;
  // touch-action: pan-x, pan-y;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -ms-touch-action: none;
  touch-action: none;
  // background: linear-gradient(
  //   200deg,
  //   var(--blue-bg-top) 15.79%,
  //   var(--blue-bg-bottom) 85.68%
  // );
  /* background-image: linear-gradient(200deg,var(--blue-bg-top) 15.79%,var(--blue-bg-bottom) 85.68%),url(/assets/images/bg.png); */
  background-size: 100%;
  background-repeat: no-repeat;
  color: var(--dark-black-color);
  font-family: "Kanit";
  font-size: 16px;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  width: 100%;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;

}

img {
  -webkit-user-drag: none;
}

a {
  -webkit-user-drag: none !important;

  &.link-navigation-on-detail {
    text-decoration: none !important;
    background-color: #EDF5FF !important;
    color: #2B77FF !important;
    border-radius: 16px;
    padding: 2px 8px 2px 8px;
    display: inline-block;
    cursor: default;
    margin: 4px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    vertical-align: middle;
  }

  &.link-navigation-on-detail:before {
    content: '';
    background: url(assets/icon/link.png) no-repeat;
    background-size: cover;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin-right: 4px;
  }
}

iframe {
  width: 100% !important;
}

.column {
  padding: 1rem;
}

.button.is-medium {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 18px;
}

.button img {
  margin-right: 12px;
}

.notification {
  border-radius: 12px 12px 0px 0px;
  padding: 16px 16px 16px 16px;
  min-height: calc(100vh - 87px);
  box-sizing: border-box;
  margin-bottom: 0 !important;
  background-color: var(--gray-light-disabled);

  &.mg-CNX {
    min-height: calc(100svh - 71px);
  }
}

.android-device {
  .notification {
    min-height: calc(100vh - 87px);
  }
}

.w-100 {
  width: 100%;
}

hr {
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.link-item {
  &:hover {
    color: inherit;
  }
}

.pt-8 {
  padding-top: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-16 {
  margin-top: 16px;
}

.pt-16 {
  padding-top: 16px !important;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.pl-16 {
  padding-left: 16px !important;
}

.pd-content {
  margin-top: 16px;
}

.wrap-card {
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: 0 8px 15px -8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  img {
    cursor: pointer;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
  }

  &.full {
    margin-bottom: 25px;
    background: var(--white-color);
    box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.1);
  }
}

.header {
  &.without-header {
    margin-top: -28px;
  }

  // &.header-flex {
  //   display: flex;
  //   padding-right: 1rem;
  //   padding-left: 1rem;
  //   height: auto;
  //   align-items: center;
  // }

  h3 {
    font-size: 20px;
    line-height: 44px;
  }

  .header-title {
    flex-grow: 2;
  }

  .header-temp-last {
    width: 30px;
  }
}

.font-black {
  color: var(--dark-black-color);
}

/* toast */
.notification.toast-noti {
  min-height: auto;
  z-index: 100;
  // top: 52px;
}

.toast-icon {
  padding-top: 10px;
}

.toast-noti {
  color: var(--white-color);
  position: absolute;
  width: 95%;
  top: 0;
  left: 2.5%;
  padding: 16px;
  background: linear-gradient(94.84deg, #7ebb2a 0%, #62a507 100%);
  border-radius: 8px;
  z-index: 100;
  margin-top: 52px;

  table {
    vertical-align: middle;

    span {
      display: block;
      font-family: "Kanit-Medium";
    }
  }

  .pt-1 {
    padding-top: 0.25rem !important;
  }
}

// ------- MASTER ---------

// ------- BG ---------
.bg-blue {
  // padding-top: 44px;
  font-size: 20px;
  color: var(--dark-black-color);
  // background: transparent url(assets/images/bg.png) top right no-repeat;
  overflow: hidden;

  // background-size: contain;
  .pd-content {
    color: var(--dark-black-color);
  }

  &.bg-blue-android-device {
    // padding-top: 35px;
  }

  &.reletive-content {
    overflow-y: scroll;
    position: relative;
  }
}

.bg-special {
  padding-top: 44px;
}

.bg-standard {
  background-image: linear-gradient(200deg, var(--blue-bg-top) 15.79%, var(--blue-bg-bottom) 85.68%), url(/assets/images/bg.png);
  background-blend-mode: saturation;
  background-repeat: no-repeat;
  background-position: top right;
  padding-top: 44px;
  height: 100vh;
  background-size: cover;
}

.bg-white {
  background-color: var(--white-color);
}

.bg-green {
  background: linear-gradient(299.15deg,
      var(--light-green-color) 8.77%,
      var(--dark-green-color) 100%);
}

// ------- BG ---------

// ------- text ---------
.text-blue {
  color: var(--blue-color);
}

.text-dark-black {
  color: var(--dark-black-color);
}

.text-dark-gray {
  color: var(--gray-color);
}

.text-gray {
  color: var(--dark-gray);
}

.newline-wrap {
  white-space: pre-wrap;
  word-break: break-word;
}

.mat-dialog-container {
  display: block !important;
  padding: 0px !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  overflow: inherit !important;
  outline: 0 !important;
  width: 100% !important;
  height: 100% !important;
  min-height: inherit !important;
  max-height: inherit !important;

  .btn-blue {
    border-radius: 8px;
  }
}

.cdk-overlay-pane {
  margin-top: auto !important;
  margin-bottom: auto !important;

  &.dialog-slide-animate {
    margin-top: auto !important;
    margin-bottom: 0 !important;
  }
}


.cdk-overlay-dark-backdrop {
  background-color: rgba(33, 33, 33, 0.8);
}

/* TinyMCE */
#couponDetail {
  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-justify {
    text-align: justify;
  }

  li {
    margin-left: 16px;
  }

  ul {
    li {
      list-style: disc;
    }
  }

  sub,
  sup {
    font-size: 10px;
  }

  blockquote {
    sup {
      font-size: initial;
    }
  }
}

/* Coach Mark */
.p-overlaypanel {
  width: 100vw;
  height: 100vh;
  background: rgba(33, 33, 33, 0.8) !important;
  transform: none !important;
  margin-top: 0 !important;
  border-radius: 0 !important;

  // .p-overlaypanel-content {
  //   position: fixed;
  //   width: 100vw;
  //   height: 100vh;
  // }

  .modal-card-body {
    position: absolute;
    top: 302px;
    width: 92%;
    left: 16px;
    background: transparent;
    padding: 16px 0 !important;

    h2 {
      font-size: 18px;
      color: var(--dark-black-color);
    }

    p {
      color: var(--dark-gray);
    }
  }

  .coachCollectedButton {
    position: absolute;
    top: 251px;
    right: 16px;
    background-color: var(--white-color);
    padding: 8px;
    border-radius: 36px;

    &.isAndroid {
      top: 251px;
    }

    &.CNXChannel {
      top: 223px;

      // &.isAndroid {
      //   top: 238px;
      // }
    }

    &.NextChannel {
      top: 223px;

      // &.isAndroid {
      //   top: 238px;
      // }
    }
  }

  .btn-collect {
    background: var(--orange-color);
    border-radius: 36px;
    color: var(--white-color);
    height: 25px;
    width: 48px;
    font-size: 14px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border: 0;
  }

  .content-custom:before {
    content: "\a";
    border-style: solid;
    border-width: 8px 10px 8px 0;
    border-color: transparent #FFFFFF transparent transparent;
    position: absolute;
    top: 3px;
    right: 19px;
    transform: rotate(90deg);
  }

  .content-custom {
    // height: 147px;
    background: var(--white-color);
    border-radius: 12px;
  }

  .content-custom-top {
    // height: 91px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }

  .content-custom-bottom {
    height: 56px;
    background: var(--blue-bg-box);
    padding: 24px 16px;
    border-radius: 0 0 12px 12px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .nav-container {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .nav {
    display: flex;
    align-items: center;
  }

  .circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: var(--blue-circle);
  }

  .active {
    background: var(--blue-color);
  }

  .btn-next {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 10px;
    border: 0;
    background: transparent;
    color: var(--blue-color);
    padding: 16px 6px;
    gap: 8px;
  }

  .btn-previous {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10px;
    border: 0;
    background: transparent;
    color: var(--blue-color);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    padding: 16px 6px;
  }

  .btn-start {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 16px;
    border: 0;
    height: 32px;
    background: var(--blue-color);
    border-radius: 8px;
    color: var(--white-color);
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 4px 16px;
  }

  &.overlay-first {
    &.inCNXChannel {
      .modal-card-body {
        top: 275px;
      }
    }

    &.inNextChannel {
      .modal-card-body {
        top: 275px;
      }
    }
  }

  &.overlay-second {
    top: 168px !important;
    background: rgba(33, 33, 33, 0) !important;


    &.isAndroid {
      top: 168px !important;
    }

    &.CNXChannel {
      top: 144px !important;

      // &.isAndroid {
      //   top: 158px !important;
      // }
    }

    &.NextChannel {
      top: 144px !important;

      // &.isAndroid {
      //   top: 158px !important;
      // }
    }

    .modal-card-body {
      top: 2px;
    }

    .content-custom:before {
      right: 19px;
    }
  }

  &.overlay-third {
    top: 100px !important;
    background: rgba(33, 33, 33, 0) !important;


    &.isAndroid {
      top: 100px !important;
    }

    &.CNXChannel {
      top: 76px !important;

      // &.isAndroid {
      //   top: 90px !important;
      // }
    }

    &.NextChannel {
      top: 76px !important;

      // &.isAndroid {
      //   top: 90px !important;
      // }
    }

    .modal-card-body {
      top: 6px;
    }

    .content-custom:before {
      right: 19px;
    }
  }
}

.p-overlaypanel:before,
.p-overlaypanel::after {
  display: none;
}

.coach-shadow {
  // margin: 8px 8px 0 8px;
  box-shadow: 0 0 0 9999px rgba(33, 33, 33, .8);
  -webkit-box-shadow: 0 0 0 9999px rgba(33, 33, 33, .8);
  -moz-box-shadow: 0 0 0 9999px rgba(33, 33, 33, .8);
  border-radius: 16px;
  pointer-events: none;
  position: absolute;
  width: 56px;
  height: 56px;
  top: -8px;
  left: -8px;
  z-index: 1000;

  // .column {
  //   padding: 0.88rem 0.5rem;
  // }
}

.coach-shadow-second {
  box-shadow: 0 0 0 9999px rgba(33, 33, 33, .8);
  -webkit-box-shadow: 0 0 0 9999px rgba(33, 33, 33, .8);
  -moz-box-shadow: 0 0 0 9999px rgba(33, 33, 33, .8);
  border-radius: 129px;
  pointer-events: none;
  position: absolute;
  width: 129px;
  height: 64px;
  top: 95.3px;
  right: 8px;
  z-index: 1000;

  &.CNX-channel {
    top: 68px !important;
  }

  &.Next-channel {
    top: 68px !important;
  }
}

@media screen and (min-width: 768px) {
  .p-overlaypanel {
    .modal-card-body {
      width: 97.15%;
    }
  }
}

// loading page when collect coupon

.loading-page {
  &.loading-style {
    pointer-events: none;
  }
}

.nowrap {
  white-space: nowrap;
}

.swiper-slide {
  // width: auto !important;
}

// For image coupon empty
.coupon-empty-img {
  width: 240px;
  height: 168px;
}


/* Start Coupon Card */

.box-content {
  box-sizing: border-box;
  margin-left: 0px;
  aspect-ratio: 1.5;
  background: var(--white-color);
  width: 100%;
  height: 108px;
  padding: 8px 8px 8px 12px;
}

.shadow-content {
  box-shadow: 3px 7px 26px -10px rgba(181, 180, 180, 0.75);
  -webkit-box-shadow: 3px 7px 26px -10px rgba(181, 180, 180, 0.75);
  -moz-box-shadow: 3px 7px 26px -10px rgba(181, 180, 180, 0.75);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.mask-right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-mask-image: radial-gradient(circle at 0 0, transparent, transparent 8px, #000 8px, #000 100%), radial-gradient(circle at 0 100%, transparent, transparent 8px, #000 8px, #000 100%);
  // mask-image: radial-gradient(circle at 0 0, transparent, transparent 8px, #000 8px, #000 100%), radial-gradient(circle at 0 100%, transparent, transparent 8px, #000 8px, #000 100%);
  -webkit-mask-size: 100% 51%;
  // mask-size: 100% 51%;
  -webkit-mask-repeat: no-repeat;
  // mask-repeat: no-repeat;
  -webkit-mask-position: 0 0, 0 100%;
  // mask-position: 0 0, 0 100%;
}

.mask-right-shadow {
  top: 8px;
  left: 0px;
  opacity: 0.5;
  margin-right: 16px;
  position: absolute;
  width: calc(100% - 12px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-mask-image: radial-gradient(circle at 4px 0,
      transparent,
      transparent 8px,
      #000 8px,
      #000 100%),
    radial-gradient(circle at 4px 100%,
      transparent,
      transparent 8px,
      #000 8px,
      #000 100%);
  // mask-image: radial-gradient(circle at 4px 0,
  //     transparent,
  //     transparent 8px,
  //     #000 8px,
  //     #000 100%),
  //   radial-gradient(circle at 4px 100%,
  //     transparent,
  //     transparent 8px,
  //     #000 8px,
  //     #000 100%);
  -webkit-mask-size: 100% 51%;
  // mask-size: 100% 51%;
  -webkit-mask-repeat: no-repeat;
  // mask-repeat: no-repeat;
  -webkit-mask-position: 0 0, 0 100%;
  // mask-position: 0 0, 0 100%;
}

.mask-left {
  width: 108px;
  height: 108px;
  object-fit: cover;
  object-position: top center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 1px dashed var(--gray-light-disabled);
  background-color: var(--white-color);
  -webkit-mask-image: radial-gradient(circle at 108px 0,
      transparent,
      transparent 8px,
      #000 8px,
      #000 100%),
    radial-gradient(circle at 108px 100%,
      transparent,
      transparent 8px,
      #000 8px,
      #000 100%);
  // mask-image: radial-gradient(circle at 108px 0,
  //     transparent,
  //     transparent 8px,
  //     #000 8px,
  //     #000 100%),
  //   radial-gradient(circle at 108px 100%,
  //     transparent,
  //     transparent 8px,
  //     #000 8px,
  //     #000 100%);
  -webkit-mask-size: 100% 51%;
  // mask-size: 100% 51%;
  -webkit-mask-repeat: no-repeat;
  // mask-repeat: no-repeat;
  -webkit-mask-position: 0 0, 0 100%;
  // mask-position: 0 0, 0 100%;
}

.mask-left-shadow {
  top: 8px;
  left: 12px;
  opacity: 0.5;
  width: 100px;
  height: 108px;
  object-fit: cover;
  position: absolute;
  object-position: top center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 1px dashed var(--gray-light-disabled);
  background-color: var(--white-color);

  -webkit-mask-image: radial-gradient(circle at 100px 0,
      transparent,
      transparent 8px,
      #000 8px,
      #000 100%),
    radial-gradient(circle at 100px 100%,
      transparent,
      transparent 8px,
      #000 8px,
      #000 100%);
  // mask-image: radial-gradient(circle at 100px 0,
  //     transparent,
  //     transparent 8px,
  //     #000 8px,
  //     #000 100%),
  //   radial-gradient(circle at 100px 100%,
  //     transparent,
  //     transparent 8px,
  //     #000 8px,
  //     #000 100%);
  -webkit-mask-size: 100% 51%;
  // mask-size: 100% 51%;
  -webkit-mask-repeat: no-repeat;
  // mask-repeat: no-repeat;
  -webkit-mask-position: 0 0, 0 100%;
  // mask-position: 0 0, 0 100%;
}

/* End Coupon Card */