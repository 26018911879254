@font-face {
  font-family: "Kanit";
  src: url(/assets/font/Kanit-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Kanit-SemiBold";
  src: url(/assets/font/Kanit-SemiBold.eot);
  src: local('Kanit-SemiBold'), url(/assets/font/Kanit-SemiBold.eot?#iefix) format('embedded-opentype'),
    url(/assets/font/Kanit-SemiBold.ttf) format("truetype"),
    url(/assets/font/Kanit-SemiBold.otf) format('otf'),
    url(/assets/font/Kanit-SemiBold.svg#Kanit-SemiBold) format('svg'),
    url(/assets/font/Kanit-SemiBold.woff2) format('woff2'), //Will be preloaded
    url(/assets/font/Kanit-SemiBold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Kanit-Medium";
  src: url(/assets/font/Kanit-Medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Kanit";
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Kanit-SemiBold";
  letter-spacing: -0.5px;
}

.font-kani-semibold {
  font-family: "Kanit-SemiBold";
}

.android-device {

  h1,
  h2,
  h3,
  h4,
  h5 {
    letter-spacing: 0;
  }

  .font-kani-semibold {
    letter-spacing: 0;
  }
}

.font-medium {
  font-family: "Kanit-Medium";
}