button {
    font-family: inherit;
    letter-spacing: -0.5px;
    border: 0;
}

.button {
    border-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
}

.button.is-primary:active,
.button.is-primary:hover {
    background-color: var(--blue-color);
}

.button:hover,
.button:active,
.button:focus {
    border-color: transparent;
}

.button:focus-visible {
    outline: auto;
}

.button:focus:not(:active),
.button.is-focused:not(:active) {
    box-shadow: none;
}

.android-device button {
    letter-spacing: 0;
}

.btn-orange {
    background: var(--orange-color);
    border-radius: 24px;
    color: var(--white-color);
    height: 48px;
}

.btn-orange:hover{
    // background-color: var(--orange-link);
    color: white;
}
.btn-orange:active,
.btn-orange:focus {
    color: var(--white-color);
}

.btn-blue {
    // background: linear-gradient(299.15deg, var(--blue-color) 8.77%, var(--blue-light-color) 100%);
    background: var(--blue-color);
    border-radius: 24px;
    color: var(--white-color);
    height: 48px;
}

.btn-blue:hover{
    // background-color: #2262D8;
    color: white;
}
.btn-blue:active,
.btn-blue:focus {
    color: var(--white-color);
}

.btn-blue-outline {
    background: var(--white-color);
    border-radius: 8px;
    border: solid 1px var(--blue-color);
    color: var(--blue-color);
}

.btn-red {
    background: var(--red-drak-btn);
    border-radius: 24px;
    color: var(--white-color);
    height: 48px;
}

.btn-red:hover{
    // background-color: #BB0000;
    color: white;
}
.btn-red:active,
.btn-red:focus {
    color: var(--white-color);
}

.btn-gray,
.btn-gray:hover,
.btn-gray:active,
.btn-gray:focus {
    background-color: var(--gray-btn-disabled) !important;
    color: var(--white-color);
    border-radius: 24px;
    height: 48px;
}

.modal-card-body {

    .btn-blue-outline:hover{
        border-color: #2262D8;
        color: #2262D8;
    }
    .btn-blue-outline:active,
    .btn-blue-outline:focus {
        color: #2262D8;
        border-color: #2262D8;
    }
}